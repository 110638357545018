import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Gallery from "./components/Gallery";
import Banner from "./components/Banner";
import Portadas from "./components/Portadas";
import Portada from "./components/Portada";
import Footer from "./components/Footer";
import Menu from "./components/navbar/Menu";

function App() {
  return (
    <>
      <BrowserRouter>
        <Menu />
      
   
<Routes>
        <Route path="/Acerca" element={<Portada />} />
        <Route path="/Eventos" element={<Portadas />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Redes" element={<Footer />} />
        </Routes>
        <Banner />
        <Portada />
        <Portadas />
        <Gallery />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
