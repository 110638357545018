// Portadas.js

import React from 'react';
import './Portadas.css'; // Estilos para el componente

const Portadas = () => {
  // Array de objetos con URL de imagen, título y descripción
  const portadas = [
    {
      url: '../img/dec.jpeg',
      title: 'Boda',
      description: 'Festeja tu boda soñada',
    },
    {
      url: '../img/dec0.jpg',
      title: 'Cumpleaños',
      description: 'Festeja tu cumpleaños',
    },
    {
        url: '../img/dec2.jpeg',
        title: 'Picnic',
        description: 'Realiza el picnic soñado ',
      },
      {
        url: '../img/dec3.jpeg',
        title: 'Recepciones',
        description: 'Festeja tu recepcion o colacion con nosotros',
      },
      {
        url: '../img/dec2.jpeg',
        title: 'Reuniones',
        description: 'Reuniones de trabajo o negocio',
      },
      {
        url: '../img/dec1.jpeg',
        title: 'Eventos',
        description: 'Realizamos toda clase de eventos',
      },
    // Agrega más objetos según sea necesario
  ];

  return (
    <div className="portadas">
      {portadas.map((portada, index) => (
        <div key={index} className="portada-item">
          <img src={portada.url} alt={`Portada ${index + 1}`} />
          <div className="portada-info">
            <h3>{portada.title}</h3>
            <p>{portada.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Portadas;
