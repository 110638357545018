import React from 'react';
import './Gallery.css'; // Estilos para el componente

const Gallery = () => {
  // Array de objetos con URL de imagen, título y descripción
  const images = [
    {
      url: './img/imagen1.jpg',
      title: 'Bodas',
      description: '',
    },
    {
      url: './img/imagen2.jpg',
      title: 'Cumpleaños',
      description: '',
    },
    {
      url: './img/imagen0.jpg',
      title: 'Picnic',
      description: '',
    },
    {
      url: './img/imagen4.jpg',
      title: 'Reuniones',
      description: '',
    },
    {
      url: './img/imagen5.jpg',
      title: 'Empresariales',
      description: '',
    },
    
    {
      url: './img/imagen9.jpg',
      title: 'Despedidas',
      description: '',
    },
    {
      url: './img/imagen3.jpg',
      title: 'Recepciones',
      description: '',
    },
  
    {
      url: './img/imagen6.jpg',
      title: 'Eventos',
      description: '',
    },
   /* {
      url: './img/imagen6.jpg',
      title: 'Inolvidables',
      description: '',
    },
    {
      url: './img/imagen7.jpg',
      title: 'Boda de Oro',
      description: '',
    },
    {
      url: './img/imagen8.jpg',
      title: 'Boda de Plata',
      description: '',
    },
      {
      url: './img/imagen1.jpg',
      title: 'Graduacion',
      description: '',
    },*/
    // Agrega más objetos según sea necesario
  ];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image.url} alt={`Imagen ${index + 1}`} />
          <div className="image-info">
            <h3>{image.title}</h3>
            <p>{image.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Gallery;
