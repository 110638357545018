import React from 'react';
import './Portada.css'; // estilos CSS

function Portada() {
  return (
    <div className="portada-container">
      <div className="acerca-de-nosotros">
        <h1>Acerca de nosotros</h1>
        <p>Somos creadores de momentos y brindamos servicios para eventos sociales y corporativos.</p>
<p>Nos especializamos en la producción, organización y diseño de eventos.</p>
        <p> Nuestra experiencia y profesionalismo en fiestas para empresas garantizan la eficacia y perfección en todos los procesos de un evento, desde la planificación, la organización y ejecución del mismo.</p>
        <p>Acompañamos y asesoramos a nuestros clientes en cada momento.</p>
        <p>Contamos con una lista de proveedores, los cuales ayudan a que nuestro evento sea un éxito.</p>
        <p>Nuesto éxito se basa, en el excelente vinculo que se establece con el cliente, interpretando sus deseos, gustos y objetivos.</p>
      </div>
      <div className="imagen">
        <img src="../../img/dec3.jpeg" alt="Imagen de la empresa" />
      </div>
     
    </div>
  );
}

export default Portada;
