import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-info">
        <h6>ORGANIZAMOS TU EVENTO PERSONALIZADO </h6>

        <p>TELEFONO <a href="tel:0111566944247" target="_blank" rel="noreferrer">011 1566944247
        
        </a></p>

         <p>EMAIL <a href="mailto/info@theplanner.com" target="_blank" rel="noreferrer">
         info@theplanner.com</a></p>
      </div>
      <div className="footer-sns">
        <div className="design-by">SEGUINOS</div>
        <div className="sns-links">
          <a href="https://www.instagram.com/planner_ep/" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram instagram "></i></a>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook facebook" ></i>
          </a>

          <a href="mailto:info@theplanner.com"
            target="_blank"
            rel="noreferrer">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </a>
          <a href="https://wa.me/+5491166944247" target="_blank" rel="noreferrer">
            <i className="fab fa-whatsapp whatsapp"></i></a>


        </div>
        <div className="footer-contact">

          <h5>BUENOS AIRES</h5>

          <h5><a href="https://www.disenowebdany.store/"
             target="_blank"
             rel="noreferrer">©Diseñado por Dany </a></h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;